import React from 'react'

const CustomerHeader = () => {
  return (
    <div className='flex justify-center items-center min-h-screen'>
      <h1>Customer header Page</h1>
    </div>
  )
}

export default CustomerHeader
