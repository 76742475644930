import React from 'react'

const CustomerDetails = () => {
  return (
    <div className='flex justify-center items-center min-h-screen'>
      <h1>Customer Details Page</h1>
    </div>
  )
}

export default CustomerDetails
