import React from 'react'

const Duplication = () => {
  return (
    <div className='flex justify-center items-center min-h-screen'>
      <h1>Dupliaction Page</h1>
    </div>
  )
}

export default Duplication
