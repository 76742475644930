import React from 'react'

const DataUpload = () => {
  return (
    <div className='flex justify-center items-center min-h-screen'>
      <h1>Data Uplaod Page</h1>
    </div>
  )
}

export default DataUpload
