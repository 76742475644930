import { Routes, Route, useLocation } from "react-router-dom";
import Login from "./Pages/Login";
import SignUp from "./Pages/SignUp";
import Header from "./Components/Header";
import CustomerDetails from "./Pages/CustomerDetails";
import DataUpload from "./Pages/DataUpload";
import PartsCatalog from "./Pages/PartsCatalog";
import CustomerHeader from "./Pages/CustomerHeader";
import QuoteEstimation from "./Pages/QuoteEstimation";
import FinalQuote from "./Pages/FinalQuote";
import QuoteDetails from "./Pages/QuoteDetails";
import Duplication from "./Pages/Duplication";
import Sidebar from "./Components/Sidebar";
import Setting from "./Pages/Setting";

function MainApp() {
  const location = useLocation();
  const hideLayout = location.pathname === "/login" || location.pathname === "/signup";

  return (
    <div className="App h-screen flex">
      {!hideLayout && <Sidebar />}
      <div className="flex-1">
        {!hideLayout && <Header />}
        <Routes>
          <Route path="/customer_details" element={<CustomerDetails />} />
          <Route path="/data_upload" element={<DataUpload />} />
          <Route path="/parts_catalog" element={<PartsCatalog />} />
          <Route path="/customer_header" element={<CustomerHeader />} />
          <Route path="/quote_estimate" element={<QuoteEstimation />} />
          <Route path="/final_quote" element={<FinalQuote />} />
          <Route path="/quote_details" element={<QuoteDetails />} />
          <Route path="/quote_duplication" element={<Duplication />} />
          <Route path="/setting" element={<Setting />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<SignUp />} />
        </Routes>
      </div>
    </div>
  );
}


export default MainApp;
