import React from 'react'
import { FaCircleUser } from "react-icons/fa6";
import Logo from "../Assets/logo.png"
import { useLocation } from 'react-router-dom';

const Header = () => {
  const location = useLocation();

  // Map route paths to header titles
  const getTitle = (path) => {
    switch (path) {
      case '/customer_details':
        return 'Customer Details';
      case '/data_upload':
        return 'Data Upload';
      case '/parts_catalog':
        return 'Parts Catalog';
      case '/customer_header':
        return 'Customer Header';
      case '/quote_estimate':
        return 'Quote Estimation';
      case '/final_quote':
        return 'Final Quote';
      case '/quote_details':
        return 'Quote Details';
      case '/quote_duplication':
        return 'Duplication';
      case '/setting':
        return 'Setting';
      default:
        return 'Welcome';
    }
  };

  return (
    <div className='fixed top-0 left-0 w-full flex justify-between items-center p-5 shadow-md border bg-white z-50'>
      <div className='w-24'>
        <img src={Logo} alt="logo" className='w-full' />
      </div>

      <div>
        <h1 className='text-[#001f3f] text-sm lg:text-3xl md:text-2xl sm:text-xl font-semibold font-sans'>{getTitle(location.pathname)}</h1>
      </div>

      <div className='flex gap-3 items-center'>
        <button className='bg-slate-900 p-2 w-16 md:w-20 text-white rounded-md'>Delete</button>
        <button className='bg-[#39cccc] p-2 w-16 md:w-20 text-white rounded-md'>Add</button>
        <FaCircleUser size={30} className='hidden md:block' />
      </div>
    </div>
  );
};

export default Header;
