import React from 'react'

const Setting = () => {
  return (
    <div className='flex justify-center items-center min-h-screen' >
      <h1>Setting Page</h1>
    </div>
  )
}

export default Setting
