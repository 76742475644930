import React from 'react'

const QuoteDetails = () => {
  return (
    <div className='flex justify-center items-center min-h-screen'>
      <h1>Quote Details Page</h1>
    </div>
  )
}

export default QuoteDetails
