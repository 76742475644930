import React from 'react'

const PartsCatalog = () => {
  return (
    <div className='flex justify-center items-center min-h-screen'>
      <h1>Parts Catalog Page</h1>
    </div>
  )
}

export default PartsCatalog
