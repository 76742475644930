import React from "react";
import { NavLink } from "react-router-dom";
import { MdOutlineFileUpload } from "react-icons/md";
import { FaUserGroup } from "react-icons/fa6";
import { HiOutlineCalculator, HiOutlineDuplicate } from "react-icons/hi";
import { FaRegListAlt } from "react-icons/fa";
import { IoIosSettings } from "react-icons/io";
import { AiOutlineUnorderedList } from "react-icons/ai";
import { TiDocumentText } from "react-icons/ti";

const Sidebar = () => {
  return (
    <div className="p-10 h-screen bg-gray-100">
      <ul className="flex flex-col items-start gap-5 my-20">
        <li className="flex items-center gap-2 font-thin hover:bg-slate-300 px-5 py-2 rounded-md">
          <MdOutlineFileUpload />
          <NavLink to="/data_upload">Data Upload</NavLink>
        </li>
        <li className="flex items-center gap-2 font-thin hover:bg-slate-300 px-5 py-2 rounded-md">
          <AiOutlineUnorderedList />
          <NavLink to="/parts_catalog">Parts Catalog</NavLink>
        </li>
        <li className="flex items-center gap-2 font-thin hover:bg-slate-300 px-5 py-2 rounded-md">
          <FaUserGroup />
          <NavLink to="/customer_header">Customer Header</NavLink>
        </li>
        <li className="flex items-center gap-2 font-thin hover:bg-slate-300 px-5 py-2 rounded-md">
          <HiOutlineCalculator />
          <NavLink to="/quote_estimate">Quote Estimation</NavLink>
        </li>
        <li className="flex items-center gap-2 font-thin hover:bg-slate-300 px-5 py-2 rounded-md">
          <TiDocumentText />
          <NavLink to="/final_quote">Final Quote</NavLink>
        </li>
        <li className="flex items-center gap-2 font-thin hover:bg-slate-300 px-5 py-2 rounded-md">
          <FaRegListAlt />
          <NavLink to="/quote_details">Quote Details</NavLink>
        </li>
        <li className="flex items-center gap-2 font-thin hover:bg-slate-300 px-5 py-2 rounded-md">
          <HiOutlineDuplicate />
          <NavLink to="/quote_duplication">Duplication</NavLink>
        </li>
        <li className="flex items-center gap-2 font-thin hover:bg-slate-300 px-5 py-2 rounded-md">
          <IoIosSettings />
          <NavLink to="/setting">Setting</NavLink>
        </li>
      </ul>
    </div>
  );
};

export default Sidebar;
