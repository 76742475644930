import React from 'react'

const FinalQuote = () => {
  return (
    <div className='flex justify-center items-center min-h-screen'>
      <h1>Final Quote Page</h1>
    </div>
  )
}

export default FinalQuote
